import { Client, useClientApi } from "@multicase/portal-api/client";
import { type AuthHeaders } from "@multicase/portal-api";

export function useClients() {
  const clients = ref<Client[]>([]);
  const client = ref(new Client());

  const { getRequest, error, loading } = useRequests();
  const clientApi = useClientApi();

  const fetchClients = async (): Promise<Client[]> => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      clients.value = await clientApi.getAll(authHeaders);
    });
    return clients.value;
  };

  const fetchClient = async (id: string): Promise<Client> => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      client.value = await clientApi.get(authHeaders, id);
    });
    return client.value;
  };

  onMounted(fetchClients);

  return { clients, fetchClients, fetchClient, loading, error };
}
