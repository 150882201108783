<template>
  <div class="border border-surface-200 dark:border-surface-700 rounded m-4 p-4">
    <div class="mb-4">
      <div class="relative mx-auto">
        <img
          :src="currentFileIcon(document.documentFileType as FileType)"
          :alt="document.heading"
          class="w-12 h-12 object-cover rounded justify-center"
          @click="documentOnClick"
        />
        <span
          class="absolute top-1 right-1 bg-primary text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
        >
          {{ index + 1 }}
        </span>
      </div>
    </div>
    <div v-if="isEditing" class="flex-grow">
      <InputText
        v-model="editingName"
        :placeholder="t('customer.documents.name')"
        class="btn-change-title w-full mb-2 text-xs"
      />
      <Textarea
        v-model="editingDescription"
        :placeholder="t('customer.documents.description')"
        class="w-full mb-2 text-xs"
        rows="3"
      />
      <div class="flex justify-end space-x-2">
        <Button label="Cancel" class="p-button-text p-button-sm" @click="cancelEdit" />
        <Button label="Save" class="p-button-sm p-close-button" @click="saveEdit" />
      </div>
    </div>
    <div v-else class="flex-grow">
      <h3 class="text-xs font-semibold mb-1 truncate">
        {{ document.heading || t("customer.documents.unnamed-document") }}
      </h3>
      <p class="text-xs text-gray-600 mb-2 line-clamp-5">
        {{ document.description || t("customer.documents.no-description") }}
      </p>
      <div class="flex justify-between items-center">
        <Button icon="pi pi-pencil" class="btn-edit-document p-button-text p-button-sm" @click="startEdit" />
        <Button
          :id="`btn-trash-documents-${index}`"
          icon="pi pi-trash"
          class="p-button-text p-button-danger p-button-sm"
          @click="onConfirmDelete"
          @keydown="handleKeydown"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";
import { ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import pdfNewIcon from "@/assets/PDF_new_file_icon.svg";
import docIcon from "@/assets/DOC_file_icon.svg";
import txtIcon from "@/assets/TXT_file_icon.svg";
import { CustomerDocument } from "@/models/CustomerDocument";
import { useDocumentService } from "@/api/document/DocumentService";
import { useCumulusToast } from "@cumulus/components";
import { useToast } from "primevue/usetoast";

const { t } = useI18n();

const toast = useCumulusToast(useToast());
const { getDocumentUrl } = useDocumentService();
const confirm = useConfirm();
const props = defineProps<{
  document: CustomerDocument;
  index: number;
}>();

const emit = defineEmits<{
  (e: "deleteDocument"): void;
  (e: "updateDocument", document: CustomerDocument): void;
}>();

enum FileType {
  PDF = "pdf",
  DOC = "doc",
  DOCX = "docx",
  TXT = "txt",
  RTF = "rtf",
}

const startEdit = () => {
  isEditing.value = true;
  editingName.value = props.document.heading;
  editingDescription.value = props.document.description;
};

const saveEdit = () => {
  const updatedDocument = {
    ...props.document,
    heading: editingName.value,
    description: editingDescription.value,
  };
  emit("updateDocument", updatedDocument);
  cancelEdit();
};

const isEditing = ref(false);
const editingName = ref("");
const editingDescription = ref("");

const cancelEdit = () => {
  isEditing.value = false;
  editingName.value = "";
  editingDescription.value = "";
};

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteDocument");
    },
  });
};

const currentFileIcon = (fileType: FileType) => {
  switch (fileType) {
    case FileType.PDF:
      return pdfNewIcon;
    case FileType.DOC:
      return docIcon;
    case FileType.DOCX:
      return docIcon;
    case FileType.TXT:
      return txtIcon;
    case FileType.RTF:
      return docIcon;
    default:
      return pdfNewIcon;
  }
};

const documentOnClick = async () => {
  const documentName = props.document.documentUrl;
  if (documentName) {
    const documentUrl = await getDocumentUrl(documentName);

    if (documentUrl && documentUrl.length > 0) {
      window.open(documentUrl);
    } else {
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: t("customer.download-link-missing"),
      });
    }
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  const elementBtnComit = document.getElementById("btn-commit") as HTMLButtonElement;
  const elementBtnCancel = document.getElementById("btn-cancel");
  const elementBtnContact = document.getElementById("add-contact-button");

  const deleteButtons = Array.from(document.querySelectorAll<HTMLButtonElement>("[id^='btn-trash-documents-']"));
  const lastDeleteButton = deleteButtons[deleteButtons.length - 1];

  if (event.key === "Tab" && event.shiftKey === false && elementBtnContact === null) {
    if (lastDeleteButton === event.target) {
      nextTick(() => (elementBtnComit?.disabled ? elementBtnCancel?.focus() : elementBtnComit?.focus()));
      event.preventDefault();
    }
  }
};
</script>

<style scoped>
.c-transform {
  transition: transform 0.2s ease-in-out;
}
</style>
