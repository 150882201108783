<template>
  <PopOverSorting
    ref="op"
    v-model:selected-icon="selectedIcon"
    :search-columns="filteredColumns"
    :default-sort-field="defaultSortField"
    @on-selected-row="emit('onSelectedRow', $event)"
  />

  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selected-columns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @reset-columns="emit('resetColumns')"
  />
</template>
<script setup lang="ts">
import { ref } from "vue";
import {
  type DataTableColumn,
  type DataTableColumnExt,
  PopOverSorting,
  PopOverColumnChooser,
} from "@cumulus/components";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  searchColumns: DataTableColumn[];
  defaultSortField: DataTableColumn | undefined;
}>();

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });
const selectedColumnsComputed = defineModel<DataTableColumn[] | DataTableColumnExt[]>("selectedColumnsComputed", {
  required: true,
});
const filteredColumns = defineModel<DataTableColumnExt[]>("filteredColumns", { required: true });

const emit = defineEmits<{
  (e: "onSelectedRow", value: DataTableColumn): void;
  (e: "resetColumns", value: void): void;
  (e: "sortingPopoverVisible", value: boolean): void;
  (e: "columnChooserVisible", value: boolean): void;
}>();

const chooserRef = ref();
const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const op = ref();
const openSortList = (event: Event) => {
  op.value.toggle(event);
};

defineExpose({
  openColumnChooser,
  openSortList,
});
</script>

<style scoped lang="scss"></style>
