<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        id="last-name"
        v-model="lastNameComputed"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('customer.last-name').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="last-name"
        class="w-full"
        :invalid="val.$error"
        :fluid="true"
        aria-describedby="last-name-help"
      />
      <label for="last-name" class="c-required">
        {{ t(`customer.last-name`) }}
      </label>
    </FloatLabel>
  </div>
  <small v-if="val.$error" class="p-error" data-testid="last-name-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  lastName: string;
}>();

const emit = defineEmits<{
  (e: "update:lastName", value: string): void;
}>();

const lastNameComputed = computed<string>({
  get: () => {
    return props.lastName ?? "";
  },
  set: (value) => {
    emit("update:lastName", value);
  },
});

const rules = {
  lastName: { required },
};

const val = useVuelidate(rules, props);
</script>
