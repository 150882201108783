<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        id="organisation-number"
        v-model="organizationNumberComputed"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('customer.organisation-number').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="organization-number"
        maxlength="8000"
        :label="t('customer.organisation-number')"
        class="w-full"
        :fluid="true"
        :invalid="val.$error"
        aria-describedby="organisation-number-help"
      />
      <label for="organisation-number" class="c-required">
        {{ t(`customer.organisation-number`) }}
      </label>
    </FloatLabel>
    <small v-if="val.$error" class="p-error" data-testid="organisation-number-error">
      {{ val.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { type WritableComputedRef, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  organizationNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:organizationNumber", value: string): void;
}>();

const organizationNumberComputed: WritableComputedRef<string> = computed({
  get: () => {
    return props.organizationNumber ?? "";
  },
  set: (value) => {
    emit("update:organizationNumber", value);
  },
});

const { t } = useI18n();

const rules = {
  organizationNumber: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
