<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        icon="pi pi-arrow-left"
        data-testid="btn-cancel"
        rounded
        size="small"
        variant="text"
        @click="routeToCustomerList(true)"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800">{{ t("customer.new-customer") }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <Button id="btn-commit" class="c-circular-button mr-4" data-testid="btn-commit" @click="createCustomer">
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.create") }}</span>
      </Button>

      <Button class="c-circular-button" @click="onToggleAllPanels()">
        <i class="pi pi-clone c-default-button c-circular-icon"></i>
        <span class="px-3">{{
          panelCollapsed ? t("customer.panel.open-panels") : t("customer.panel.close-panels")
        }}</span>
      </Button>
    </div>
  </div>

  <Message
    v-for="customerItem of customersCreated"
    :key="customerItem.id"
    severity="info"
    closable
    icon="pi pi-check"
    class="mb-4"
  >
    {{ t("customer.created-message")
    }}<a :href="`/customer/edit/${createdCustomerId}`" class="customer-created-link">
      {{
        customerItem.customerType === CustomerTypeModel.Business
          ? customerItem.businessCustomer.companyName
          : customerItem.privateCustomer.firstName + " " + customerItem.privateCustomer.lastName
      }}
    </a>
  </Message>

  <div data-testid="customer-add" class="c-page-content mt-1 ml-5 pr-1">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #content>
          <div class="col-span-12 lg:col-span-8 mx-6">
            <div class="grid grid-cols-12">
              <div class="col-span-12 lg:col-span-6 mr-8">
                <FirstName v-if="!isBusinessCustomer" v-model:first-name="customer.privateCustomer.firstName" />
                <CompanyName v-if="isBusinessCustomer" v-model:company-name="customer.businessCustomer.companyName" />
              </div>
              <div class="col-span-12 lg:col-span-6">
                <OrganizationNumber
                  v-if="isBusinessCustomer"
                  v-model:organization-number="customer.businessCustomer.organizationNumber"
                />
                <LastName v-if="!isBusinessCustomer" v-model:last-name="customer.privateCustomer.lastName" />
              </div>
              <div class="col-span-12 lg:col-span-6 mr-8">
                <CustomerNumber v-model:customer-number="customer.customerNumber" />
              </div>
              <div class="col-span-12 lg:col-span-6">
                <CustomerEmail v-model:email-address="customer.email" />
              </div>
              <div class="col-span-12 lg:col-span-6 mb-2 mr-8">
                <PhoneInput id="customer-phone" v-model="customer.phoneNumber" />
              </div>
              <div class="col-span-12 lg:col-span-6 mb-2">
                <CustomerGroup v-model:customer-groups="customer.customerGroupIds" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4 mx-5">
            <div class="col-span-12">
              <div class="mt-4 mb-5">
                <CustomerType v-model:customer-type="customer.customerType" />
              </div>

              <div class="mb-5">
                <CustomerState v-model:customer-state="customer.customerState" />
              </div>

              <div class="mb-3">
                <DocumentLanguage v-model:document-language="customer.documentLanguageIso" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      :header="t('common.addresses')"
      toggleable
      :collapsed="panelCollapsedAdresses"
      class="c-card-font mb-4"
      :pt:header:on-click="() => (panelCollapsedAdresses = !panelCollapsedAdresses)"
    >
      <div class="sm:flex mb-4">
        <div class="w2-full flex-1 xl:border-r-2 mx-6">
          <Address
            :selected-country="customer.deliveryAddress.countryIso"
            :address="customer.deliveryAddress"
            :country-options="countryOptions"
            :address-type="addressTypeDelivery"
            :address-label="t('customer.address.delivery')"
            @set-address="setDeliveryAddress"
            @auto-select-country="autoSelectedCountry"
          />
        </div>
        <div class="w-full flex-1 pl-2">
          <Address
            :selected-country="customer.address.countryIso"
            :address="customer.address"
            :country-options="countryOptions"
            :address-type="addressTypeInvoice"
            :address-label="t('customer.address.invoice')"
            @set-address="setCompanyAddress"
            @auto-select-country="autoSelectedCountry"
          />
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('customer.terms-and-agreements')"
      toggleable
      :collapsed="panelCollapsedTermsAgreement"
      class="c-card-font mb-4"
      :pt:header:on-click="() => (panelCollapsedTermsAgreement = !panelCollapsedTermsAgreement)"
    >
      <div class="grid grid-cols-12 mb-4">
        <Card class="col-span-12 lg:col-span-7 shadow-none" pt:body:class="px-2 py-0">
          <template #content>
            <div class="w-full">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mx-4">
                <div class="mb-2 mt-4">
                  <DefaultFreightMethod
                    v-model:default-freight-method-id="customer.freight.defaultFreightMethodId"
                    :freight-methods="freightMethods"
                  />
                </div>
                <div class="mb-2 mt-4">
                  <DefaultPaymentTerm v-model:payment-term-id="customer.payment.defaultPaymentTermId" />
                </div>
                <div class="mb-2 mt-3">
                  <CreditLimit v-model:credit-limit="customer.payment.creditLimit" />
                </div>
                <div class="mb-2 mt-4">
                  <Currency v-model:currency="customer.payment.currencyIso" />
                </div>
              </div>
            </div>
            <div class="w-full mt-6">
              <div class="mb-2 mx-4">
                <FreightAgreements
                  v-model:freight-agreements="customer.freight.freightAgreements"
                  :freight-methods="freightMethods"
                />
              </div>
            </div>
          </template>
        </Card>

        <Card class="col-span-12 lg:col-span-5 shadow-none lg:border-l rounded-none" pt:body:class="p-4">
          <template #content>
            <div class="grid grid-cols-1 lg:grid-cols-12 gap-4">
              <div class="lg:col-span-5 lg:border-r lg:pr-4">
                <h3 class="text-base font-medium mb-4">{{ t("customer.delivery-preferences.header") }}</h3>
                <DeliveryPreferences v-model:customer="customer" :default-select-first="true" />
              </div>
              <div class="lg:col-span-7 lg:pl-4">
                <Documents v-model:documents="customer.documents" />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </Panel>
    <Panel
      v-if="isBusinessCustomer"
      :header="t('customer.contact-list.label')"
      toggleable
      :collapsed="panelCollapsedContactList"
      class="c-card-font mb-8"
      :pt:header:on-click="() => (panelCollapsedContactList = !panelCollapsedContactList)"
    >
      <div class="w-full mb-6">
        <ContactList v-model:contacts="customer.businessCustomer.contacts" />
      </div>
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { ShortcutAction, useShortcut, useUnsavedChanges } from "@cumulus/components";
import { useCustomerAddress } from "../composables/useCustomerAddress";

import { CustomerType as CustomerTypeModel } from "@multicase/portal-api/customer";
import type { Country } from "@/models/country/Country";
import type { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useCustomer } from "@/composables/useCustomers";
import { useCountry } from "@/api/country/CountryService";
import { useFreightMethod } from "@/repositories/freight-method/FreightMethodService";
import { useAuth } from "@cumulus/event-bus";
import { useClients } from "@/composables/useClients";

const { customer, customersCreated, createdCustomerId, isChanged, createCustomer, resetInitialCustomer } =
  useCustomer();
const { getAllCountries } = useCountry();
const { getAllFreightMethods } = useFreightMethod();

const { t } = useI18n();
const router = useRouter();

const addressTypeDelivery = ref("delivery");
const addressTypeInvoice = ref("invoice");
const { setCompanyAddress, setDeliveryAddress } = useCustomerAddress(customer);

const confirmedDiscard = ref(false);

const routeToCustomerList = (confirmed: boolean) => {
  confirmedDiscard.value = confirmed;
  if (window.history.state.back === null || window.history.state.back.indexOf("/customer/search") === -1) {
    router.push({ name: "customer-search", query: { search: "" } });
  } else {
    router.back();
  }
};

const isBusinessCustomer = computed<boolean>(() => {
  return customer.value.customerType === CustomerTypeModel.Business;
});

const countryOptions = ref<Country[]>([]);
const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const freightMethods = ref<FreightMethod[]>([]);
const fetchFreightMethods = async () => {
  freightMethods.value = await getAllFreightMethods();
};

const autoSelectedCountry = (countryIso: string) => {
  if (customer.value.deliveryAddress.countryIso === "") {
    customer.value.deliveryAddress.countryIso = countryIso;
    customer.value.address.countryIso = countryIso;
  }
};

const { getAuthHeaders } = useAuth();
const { fetchClient } = useClients();

const panelCollapsedAdresses = ref(false);
const panelCollapsedTermsAgreement = ref(false);
const panelCollapsedContactList = ref(false);

const panelCollapsed = computed(() => {
  return panelCollapsedAdresses.value && panelCollapsedTermsAgreement.value && panelCollapsedContactList.value;
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedAdresses.value = newState;
  panelCollapsedTermsAgreement.value = newState;
  panelCollapsedContactList.value = newState;
};

onMounted(async () => {
  const client = await fetchClient((await getAuthHeaders()).clientId);
  customer.value.address.countryIso = client.countryIso;
  customer.value.payment.currencyIso = client.currencyIso;
  customer.value.documentLanguageIso = client.countryIso;

  await Promise.all([fetchCountryData(), fetchFreightMethods()]);
  resetInitialCustomer();
});

useShortcut(ShortcutAction.save, createCustomer);

useUnsavedChanges(isChanged);
</script>

<style scoped lang="scss">
:deep(.p-message.p-message-info) {
  background: var(--c-message-info-bg);
  color: var(--c-message-info-text);
}

.customer-created-link {
  color: var(--c-blue-50);
  text-decoration: underline;
}

.c-card-font {
  font-size: 12.8px;
}
</style>
