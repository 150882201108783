import { CustomerType } from "@multicase/portal-api/customer";

export class SearchFilters {
  customerTypes: CustomerType[] = [];
  customerGroups: string[] = [];

  public equals = (other: SearchFilters): boolean => {
    return this.customerTypes === other.customerTypes && this.customerGroups === other.customerGroups;
  };
}
