import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { type AuthHeaders } from "@multicase/portal-api";

export function useRequests() {
  const loading = ref<boolean>(false);
  const saving = ref<boolean>(false);
  const error = ref<unknown | null>(null);

  const { getAuthHeaders, instance } = useAuth();

  const baseRequest = async <T>(
    fn: (authHeaders: AuthHeaders) => Promise<T>,
    loadingState: Ref<boolean>,
  ): Promise<T | undefined> => {
    loadingState.value = true;
    error.value = null;

    try {
      const authHeaders = await getAuthHeaders();
      return await fn(authHeaders);
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
        return undefined;
      }

      error.value = e;
      console.error(e);
      return undefined;
    } finally {
      loadingState.value = false;
    }
  };

  const getRequest = async <T>(fn: (authHeaders: AuthHeaders) => Promise<T>): Promise<T | undefined> => {
    return baseRequest(fn, loading);
  };

  const mutationRequest = async <T>(fn: (authHeaders: AuthHeaders) => Promise<T>): Promise<T | undefined> => {
    return await baseRequest(fn, saving);
  };

  return {
    loading,
    saving,
    error,
    getRequest,
    mutationRequest,
  };
}
