<template>
  <div class="grid grid-cols-1 gap-4 mr-8">
    <CumulusSelect
      v-model="deliveryPreferences.orderConfirmation"
      :options="orderConfirmationOptions"
      :option-label="'label'"
      :option-value="'value'"
      :default-select-first="defaultSelectFirst"
      :label="t(`customer.delivery-preferences.order-confirmation`)"
      test-id="delivery-option-order-confirmation"
    />
    <CumulusSelect
      v-model="deliveryPreferences.invoice"
      :options="invoiceOptions"
      option-label="label"
      option-value="value"
      :default-select-first="defaultSelectFirst"
      :label="t(`customer.delivery-preferences.invoice`)"
      test-id="delivery-option-invoice"
    />
    <CumulusSelect
      v-model="deliveryPreferences.creditNote"
      :options="creditNoteOptions"
      option-label="label"
      option-value="value"
      :default-select-first="defaultSelectFirst"
      :label="t(`customer.delivery-preferences.credit-note`)"
      test-id="delivery-option-credit-note"
    />
  </div>
</template>

<script setup lang="ts">
import {
  CreditNoteB2bFormat,
  CreditNoteB2cFormat,
  CustomerType,
  InvoiceB2bFormat,
  InvoiceB2cFormat,
  OrderConfirmationB2bFormat,
  OrderConfirmationB2cFormat,
  Customer,
} from "@multicase/portal-api/customer";
import { CumulusSelect } from "@cumulus/components";

const { t } = useI18n();

const customer = defineModel<Customer>("customer", {
  required: true,
});

defineProps<{
  defaultSelectFirst: boolean;
}>();

const deliveryPreferences = computed(() => {
  return customer.value.customerType === CustomerType.Business
    ? customer.value.businessCustomer.deliveryPreferences
    : customer.value.privateCustomer.deliveryPreferences;
});

const orderConfirmationOptions = computed(() => {
  return customer.value.customerType === CustomerType.Business
    ? Object.values(OrderConfirmationB2bFormat).map((value) => ({
        value: value,
        label: t(`customer.delivery-preferences.order-confirmation-formats.${value.toLowerCase()}`),
      }))
    : Object.values(OrderConfirmationB2cFormat).map((value) => ({
        value: value,
        label: t(`customer.delivery-preferences.order-confirmation-formats.${value.toLowerCase()}`),
      }));
});

const invoiceOptions = computed(() => {
  return customer.value.customerType === CustomerType.Business
    ? Object.values(InvoiceB2bFormat).map((value) => ({
        value: value,
        label: t(`customer.delivery-preferences.invoice-formats.${value.toLowerCase()}`),
      }))
    : Object.values(InvoiceB2cFormat).map((value) => ({
        value: value,
        label: t(`customer.delivery-preferences.invoice-formats.${value.toLowerCase()}`),
      }));
});

const creditNoteOptions = computed(() => {
  return customer.value.customerType === CustomerType.Business
    ? Object.values(CreditNoteB2bFormat).map((value) => ({
        value: value,
        label: t(`customer.delivery-preferences.credit-note-formats.${value.toLowerCase()}`),
      }))
    : Object.values(CreditNoteB2cFormat).map((value) => ({
        value: value,
        label: t(`customer.delivery-preferences.credit-note-formats.${value.toLowerCase()}`),
      }));
});
</script>
