import { ref } from "vue";
import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { useErrorHandler } from "../ErrorHandler";
import { CustomerSearchRequest } from "../../models/search/customer/CustomerSearchRequest";
import { CustomerSearchResponse } from "../../models/search/customer/CustomerSearchResponse";
import { ProductFrequentlyBoughtRequest } from "@/models/search/product/ProductFrequentlyBoughtRequest";
import { ProductFrequentlyBoughtResponse } from "@/models/search/product/ProductFrequentlyBoughtResponse";

export const useSearch = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const customerRequest = ref<CustomerSearchRequest | null>(null);
  const customerResponse = ref<CustomerSearchResponse | null>(null);

  const loading = ref(false);

  const customerSearch = async (
    request: CustomerSearchRequest,
    clearCache = false
  ): Promise<CustomerSearchResponse> => {
    try {
      loading.value = true;

      if (clearCache) {
        customerRequest.value = null;
        customerResponse.value = null;
      }
      const authHeaders = await getAuthHeaders();
      if (customerRequest.value && customerRequest.value?.equals(request) && customerResponse.value !== null) {
        return customerResponse.value;
      }

      customerResponse.value = await searchApi.customerSearch(authHeaders, request);
      customerRequest.value = request;
      return customerResponse.value;
    } catch (error) {
      await handleError(error);
      return new CustomerSearchResponse();
    }
  };

  let productFrequentlyBoughtRequestCache: ProductFrequentlyBoughtRequest | null = null;
  let productFrequentlyBoughtResponseCache: ProductFrequentlyBoughtResponse | null = null;

  const frequentlyBoughtProducts = async (
    request: ProductFrequentlyBoughtRequest,
    clearCache = false
  ): Promise<ProductFrequentlyBoughtResponse> => {
    try {
      loading.value = true;
      if (clearCache) {
        productFrequentlyBoughtRequestCache = null;
        productFrequentlyBoughtResponseCache = null;
      }

      const authHeaders = await getAuthHeaders();

      if (
        productFrequentlyBoughtRequestCache &&
        productFrequentlyBoughtRequestCache?.equals(request) &&
        productFrequentlyBoughtResponseCache !== null
      ) {
        return productFrequentlyBoughtResponseCache;
      }
      productFrequentlyBoughtResponseCache = (await searchApi.frequentlyBoughtProductsSearch(
        authHeaders,
        request
      )) as ProductFrequentlyBoughtResponse;
      productFrequentlyBoughtRequestCache = request;
      return productFrequentlyBoughtResponseCache;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }

    return {
      products: [],
      totalHits: 0,
    } as ProductFrequentlyBoughtResponse;
  };

  return { loading, customerSearch, frequentlyBoughtProducts };
};
