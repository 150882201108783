<template>
  <teleport to="#teleport-edit-customer-toolbar">
    <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
      <Button id="btn-commit" class="c-circular-button mr-4" data-testid="btn-commit" @click="updateCustomer">
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.save") }}</span>
      </Button>
    </div>

    <Button class="c-circular-button mr-4" data-testid="btn-delete" @click="onConfirmDelete">
      <i class="pi pi-trash c-delete-button c-circular-icon"></i>
      <span class="px-4">{{ t("common.delete") }}</span>
    </Button>

    <Button class="c-circular-button" @click="onToggleAllPanels">
      <i class="pi pi-clone c-default-button c-circular-icon"></i>
      <span class="px-3">{{
        panelCollapsed ? t("customer.panel.open-panels") : t("customer.panel.close-panels")
      }}</span>
    </Button>
  </teleport>

  <Message v-if="loadFailed" closable severity="error" class="mx-5 my-3" pt:text:class="w-full">
    <div class="w-full">
      {{ t("common.loading-failed") }} {{ errorReason }}
      <Button
        :label="t('common.reload')"
        severity="danger"
        variant="text"
        icon="pi pi-refresh"
        icon-pos="right"
        pt:label:class="underline"
        class="ml-2"
        @click="reload"
      ></Button>
    </div>
  </Message>

  <ConfirmPopup group="customer-delete-confirm" data-testid="customer-delete-confirm"></ConfirmPopup>

  <div data-testid="edit-product" class="c-page-content mt-1 ml-5 pr-1">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8 mr-4 pb-4">
        <template #header>
          <div class="flex justify-start font-semibold p-[0.375rem_1.125rem]">
            <span class="c-created-text">{{ t("common.created") }}: </span>
            <span class="c-created-date ml-1" data-testid="registered-date">{{ registeredDateComputed }}</span>
          </div>
        </template>
        <template #content>
          <div class="col-span-12 lg:col-span-8 mx-6">
            <div class="grid grid-cols-12">
              <div class="col-span-12 lg:col-span-6 mr-8">
                <FirstName v-if="!isBusinessCustomer" v-model:first-name="customer.privateCustomer.firstName" />
                <CompanyName v-if="isBusinessCustomer" v-model:company-name="customer.businessCustomer.companyName" />
              </div>
              <div class="col-span-12 lg:col-span-6">
                <OrganizationNumber
                  v-if="isBusinessCustomer"
                  v-model:organization-number="customer.businessCustomer.organizationNumber"
                />
                <LastName v-if="!isBusinessCustomer" v-model:last-name="customer.privateCustomer.lastName" />
              </div>
              <div class="col-span-12 lg:col-span-6 mr-8">
                <CustomerNumber v-model:customer-number="customer.customerNumber" />
              </div>

              <div class="col-span-12 lg:col-span-6">
                <CustomerEmail v-model:email-address="customer.email" />
              </div>
              <div class="col-span-12 lg:col-span-6 mb-2 mr-8">
                <PhoneInput id="customer-phone" v-model="customer.phoneNumber" />
              </div>
              <div class="col-span-12 lg:col-span-6 mb-2">
                <CustomerGroup v-model:customer-groups="customer.customerGroupIds" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4 mx-5">
            <div class="col-span-12">
              <div class="mt-6 mb-5">
                <CustomerType v-model:customer-type="customer.customerType" />
              </div>

              <div class="mb-5">
                <CustomerState v-model:customer-state="customer.customerState" />
              </div>

              <div class="mb-3">
                <DocumentLanguage v-model:document-language="customer.documentLanguageIso" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      :header="t('common.addresses')"
      toggleable
      :collapsed="panelCollapsedAdresses"
      class="c-card-font mb-4"
      :pt:header:on-click="() => (panelCollapsedAdresses = !panelCollapsedAdresses)"
    >
      <div class="xl:flex 2xl:flex lg:flex md:flex sm:flex mb-4">
        <div class="w2-full flex-1 xl:border-r-2 2xl:border-r-2 mx-6">
          <Address
            :selected-country="customer.deliveryAddress.countryIso"
            :address="customer.deliveryAddress"
            :country-options="countryOptions"
            :address-type="addressTypeDelivery"
            :address-label="t('customer.address.delivery')"
            @set-address="setDeliveryAddress"
            @auto-select-country="autoSelectedCountry"
          />
        </div>
        <div class="w-full flex-1 pl-2">
          <Address
            :selected-country="customer.address.countryIso"
            :address="customer.address"
            :country-options="countryOptions"
            :address-type="addressTypeInvoice"
            :address-label="t('customer.address.invoice')"
            @set-address="setCompanyAddress"
            @auto-select-country="autoSelectedCountry"
          />
        </div>
      </div>
    </Panel>

    <Panel :header="t('customer.terms-and-agreements')" toggleable :collapsed="panelCollapsed" class="c-card-font mb-4">
      <div class="grid grid-cols-12 mb-4">
        <Card class="col-span-12 lg:col-span-7 shadow-none" pt:body:class="px-2 py-0">
          <template #content>
            <div class="col-span-6 lg:col-span-3">
              <div class="grid grid-cols-4 mx-4">
                <div class="mb-2 mt-4 mr-10">
                  <DefaultFreightMethod
                    v-model:default-freight-method-id="customer.freight.defaultFreightMethodId"
                    :freight-methods="freightMethods"
                  />
                </div>
                <div class="mb-2 mt-4 mr-10">
                  <DefaultPaymentTerm v-model:payment-term-id="customer.payment.defaultPaymentTermId" />
                </div>
                <div class="mb-2 mt-3 mr-10">
                  <CreditLimit v-model:credit-limit="customer.payment.creditLimit" />
                </div>
                <div class="mb-2 mt-4 mr-4">
                  <Currency v-model:currency="customer.payment.currencyIso" />
                </div>
              </div>
            </div>
            <div class="col-span-6 lg:col-span-3">
              <div class="mb-2 mt-8 mr-8">
                <FreightAgreements
                  v-model:freight-agreements="customer.freight.freightAgreements"
                  :freight-methods="freightMethods"
                />
              </div>
            </div>
          </template>
        </Card>

        <Card class="col-span-12 lg:col-span-5 shadow-none lg:border-l rounded-none" pt:body:class="p-4">
          <template #content>
            <div class="grid grid-cols-1 lg:grid-cols-12 gap-4">
              <div class="lg:col-span-5 lg:border-r lg:pr-4">
                <h3 class="text-base font-medium mb-4">{{ t("customer.delivery-preferences.header") }}</h3>
                <DeliveryPreferences v-model:customer="customer" :default-select-first="false" />
              </div>
              <div class="lg:col-span-7 lg:pl-4">
                <Documents v-model:documents="customer.documents" />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </Panel>

    <Panel
      v-if="isBusinessCustomer"
      :header="t('customer.contact-list.label')"
      toggleable
      :collapsed="panelCollapsedContactList"
      class="c-card-font mb-8"
      :pt:header:on-click="() => (panelCollapsedContactList = !panelCollapsedContactList)"
    >
      <div class="w-full mb-6">
        <ContactList v-model:contacts="customer.businessCustomer.contacts" />
      </div>
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { useUnsavedChanges } from "@cumulus/components";
import { ShortcutAction, useShortcut } from "@cumulus/components";
import { useCustomerAddress } from "@/composables/useCustomerAddress";

import { CustomerType as CustomerTypeModel } from "@multicase/portal-api/customer";
import { type Country } from "@/models/country/Country";
import { useCustomer } from "@/composables/useCustomers";
import { type FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useCountry } from "@/api/country/CountryService";
import { useFreightMethod } from "@/repositories/freight-method/FreightMethodService";
import { useConfirm } from "primevue/useconfirm";

const { customer, isChanged, getCustomer, updateCustomer, deleteCustomer } = useCustomer();
const { getAllCountries } = useCountry();
const { getAllFreightMethods } = useFreightMethod();

const { t, d } = useI18n();
const route = useRoute();
const customerId = ref("");
const countryOptions = ref<Country[]>([]);

const loadFailed = ref(false);
const isLoading = ref(true);
const errorReason = ref("");
const addressTypeDelivery = ref("delivery");
const addressTypeInvoice = ref("invoice");
const { setCompanyAddress, setDeliveryAddress } = useCustomerAddress(customer);

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const fetchCustomerData = async () => {
  customerId.value = route.params.id as string;
  isLoading.value = true;
  loadFailed.value = false;

  try {
    await getCustomer(customerId.value);
  } catch {
    loadFailed.value = true;
  } finally {
    isLoading.value = false;
  }
};

const isBusinessCustomer = computed(() => {
  return customer.value.customerType === CustomerTypeModel.Business;
});

const autoSelectedCountry = (countryIso: string) => {
  if (customer.value.deliveryAddress.countryIso === "") {
    customer.value.deliveryAddress.countryIso = countryIso;
    customer.value.address.countryIso = countryIso;
  }
};

const freightMethods = ref<FreightMethod[]>([]);
const fetchFreightMethods = async () => {
  freightMethods.value = await getAllFreightMethods();
};
const registeredDateComputed = computed(() => {
  return customer.value.registeredDate ? d(customer.value.registeredDate, "short") : "";
});

const panelCollapsedAdresses = ref(false);
const panelCollapsedTermsAgreement = ref(false);
const panelCollapsedContactList = ref(false);

const panelCollapsed = computed(() => {
  return panelCollapsedAdresses.value && panelCollapsedTermsAgreement.value && panelCollapsedContactList.value;
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedAdresses.value = newState;
  panelCollapsedTermsAgreement.value = newState;
  panelCollapsedContactList.value = newState;
};

onMounted(async () => {
  Promise.all([fetchCustomerData(), fetchCountryData(), fetchFreightMethods()]);
});

useShortcut(ShortcutAction.save, updateCustomer);

const reload = () => {
  window.location.reload();
};

const saveButtonTooltip = computed(() => {
  return isChanged.value ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

const confirm = useConfirm();
const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    group: "customer-delete-confirm",
    message: t("customer.confirm-delete"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      deleteCustomer();
    },
  });
};

useUnsavedChanges(isChanged);
</script>

<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
