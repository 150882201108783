import { useCustomerApi, Customer } from "@multicase/portal-api/customer";
import { type AuthHeaders } from "@multicase/portal-api";
import { useRequests } from "./useRequests";
import { useErrorHandler } from "./useErrorHandler";
import useVuelidate from "@vuelidate/core";
import { useCumulusToast } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

export function useCustomer() {
  const { getRequest, mutationRequest, error, loading, saving } = useRequests();
  const { handleError } = useErrorHandler();
  const customerApi = useCustomerApi();
  const validate = useVuelidate();
  const toast = useCumulusToast(useToast());
  const router = useRouter();

  const customer = ref(new Customer());
  const initialCustomer = ref(new Customer());
  const customersCreated = ref<Customer[]>([]);
  const createdCustomerId = ref("");
  const { t } = useI18n();

  const getCustomer = async (id: string): Promise<void> => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      customer.value = await customerApi.get(authHeaders, id);
      resetInitialCustomer();
    });
  };

  const createCustomer = async (): Promise<void> => {
    validate.value.$touch();
    await validate.value.$validate();

    if (validate.value.$error) {
      addToast("warn", t("common.validation-error.summary"), t("common.validation-error.detail"));
      return;
    }

    await mutationRequest(async (authHeaders: AuthHeaders) => {
      createdCustomerId.value = await customerApi.create(authHeaders, customer.value);
    });

    if (error.value) {
      handleError(error.value);
      return;
    }

    customersCreated.value.push(customer.value);
    customer.value = new Customer();
    resetInitialCustomer();
    validate.value.$reset();
  };

  const updateCustomer = async (): Promise<void> => {
    validate.value.$touch();
    await validate.value.$validate();

    if (validate.value.$error) {
      addToast("warn", t("common.validation-error.summary"), t("common.validation-error.detail"));
      return;
    }

    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await customerApi.update(authHeaders, customer.value);
      resetInitialCustomer();
    });

    if (error.value) {
      handleError(error.value);
      return;
    }

    addToast("success", t("customer.toast.edit.success-summary"), t("customer.toast.edit.success-detail"));

    routeToCustomerList();
  };

  const deleteCustomer = async (): Promise<void> => {
    await mutationRequest(async (authHeaders: AuthHeaders) => {
      await customerApi.delete(authHeaders, customer.value.id);
    });

    addToast("success", t("customer.toast.delete.success-summary"));

    routeToCustomerList();
  };

  const isChanged = computed(() => {
    return !isEqual(customer.value, initialCustomer.value);
  });

  const resetInitialCustomer = () => {
    initialCustomer.value = cloneDeep(customer.value);
  };

  const checkCustomerNumberUsed = async (customerNumber: string): Promise<boolean | undefined> => {
    return getRequest(async (authHeaders: AuthHeaders) => {
      return await customerApi.checkCustomerNumberUsed(authHeaders, customerNumber);
    });
  };

  const routeToCustomerList = () => {
    if (window.history.state.back === null || window.history.state.back.indexOf("/customer/search") === -1) {
      router.push({ name: "customer-search", query: { search: "" } });
    } else {
      const url = new URL(window.location.origin + window.history.state.back);
      url.searchParams.set("focusResult", customer.value.id);
      router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
    }
  };

  const paramsToObject = (entries: URLSearchParams) => {
    const result = {} as Record<string, string>;
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  const addToast = (severity: string, summary: string, detail?: string): void => {
    toast.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 5000,
      closable: true,
    });
  };

  return {
    getCustomer,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    checkCustomerNumberUsed,
    routeToCustomerList,
    resetInitialCustomer,
    isChanged,
    customer,
    customersCreated,
    createdCustomerId,
    loading,
    saving,
    error,
  };
}
