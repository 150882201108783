<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        id="first-name"
        data-testid="first-name"
        v-model="firstNameComputed"
        class="w-full"
        :invalid="val.$error"
        :fluid="true"
        :maxlength="500"
        aria-describedby="first-name-help"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('customer.first-name').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label for="first-name" class="c-required">
        {{ t(`customer.first-name`) }}
      </label>
    </FloatLabel>
  </div>
  <small class="p-error" v-if="val.$error" data-testid="first-name-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  firstName: string;
}>();

const emit = defineEmits<{
  (e: "update:firstName", value: string): void;
}>();

const firstNameComputed = computed<string>({
  get: () => {
    return props.firstName ?? "";
  },
  set: (value) => {
    emit("update:firstName", value);
  },
});

const rules = {
  firstName: { required },
};

const val = useVuelidate(rules, props);
</script>
