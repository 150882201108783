<template>
  <Card class="col-span-12 md:col-span-6 2xl:col-span-4">
    <template #title><div v-if="customer !== null" class="">Sales comparison (2023 vs 2024)</div> </template>
    <template #subtitle
      ><div v-if="customer !== null" class="">A fancy chart showing invoiced sales compared to last year</div>
    </template>
    <template #content>
      <div v-if="customer === null || customer.id === ''">
        <ProgressSpinner class="w-12 flex items-center" />
      </div>
      <div v-else>
        <Chart type="line" :data="chartData" :options="chartOptions" class="h-[30rem]" />
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { Customer } from "@multicase/portal-api/customer";

defineProps<{ customer: Customer | null }>();

onMounted(() => {
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  const documentStyle = getComputedStyle(document.documentElement);

  return {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "2023 Sales",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: documentStyle.getPropertyValue("--p-cyan-500"),
        tension: 0.4,
      },
      {
        label: "2024 Sales",
        data: [28, 48, 40, 19, 86, 27, 90],
        fill: false,
        borderColor: documentStyle.getPropertyValue("--p-gray-500"),
        tension: 0.4,
      },
    ],
  };
};
const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--p-text-color");
  const textColorSecondary = documentStyle.getPropertyValue("--p-text-muted-color");
  const surfaceBorder = documentStyle.getPropertyValue("--p-content-border-color");

  return {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };
};
</script>
