<template>
  <FloatLabel variant="on">
    <Select
      id="customer-freight-method"
      v-model="selectedFreightMethodId"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('customer.freight-method').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="default-freight-method"
      :options="transformedFreightMethods"
      class="mr-8"
      fluid
      :invalid="val.defaultFreightMethodId.$error"
      option-label="name"
      option-value="value"
      pt:list:data-testid="customer-freight-method-list"
    />

    <label for="customer-freight-method">
      {{ t(`customer.freight-method`) }}
    </label>
  </FloatLabel>
  <small v-if="val.$error" class="p-error" data-testid="default-freight-method-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { type FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { nonEmptyGuid } from "@/locales/i18n-validators";
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  defaultFreightMethodId: string;
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (e: "update:defaultFreightMethodId", value: string): void;
}>();

const selectedFreightMethodId = computed<string>({
  get: () => {
    return props.defaultFreightMethodId ?? "";
  },
  set: (value) => {
    emit("update:defaultFreightMethodId", value);
  },
});

const transformedFreightMethods = computed(() =>
  props.freightMethods.map((method) => ({
    value: method.id,
    name: method.name,
  })),
);

const rules = {
  defaultFreightMethodId: {
    nonEmptyGuid,
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
