export enum DocumentType {
  CreditNote = "CreditNote",
  Invoice = "Invoice",
  OrderConfirmation = "OrderConfirmation",
  PackingNote = "PackingNote",
  PurchaseOrder = "PurchaseOrder",
  ShippingLabel = "ShippingLabel",
  PickingList = "PickingList",
  Supplier = "Supplier",
  Customer = "Customer",
  Product = "Product",
}
