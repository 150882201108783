<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-4"
          @click="routeToCustomerList"
          data-testid="btn-search"
          id="btn-search"
        >
          <i class="pi pi-search c-default-button c-circular-icon"></i>
          <span class="px-4">Customer search</span>
        </PrimeButton>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-12 grid-r2ows-3 grid-flow-col2 gap-8">
    <CustomerInfo v-model:customer="customer" />
    <InvoiceSalesChart v-model:customer="customer" />
    <FrequentlyBoughtProducts v-model:products="products" />
  </div>
</template>

<script setup lang="ts">
import CustomerInfo from "./CustomerInformation.vue";
import InvoiceSalesChart from "./InvoiceSalesChart.vue";
import FrequentlyBoughtProducts from "./FrequentlyBoughtProducts.vue";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCustomer } from "../../api/customer/CustomerService";
import { useSearch } from "../../api/search/SearchService";
import { Customer } from "../../models/customer/Customer";
import { ProductFrequentlyBoughtRequest } from "@/models/search/product/ProductFrequentlyBoughtRequest";
import { SearchProduct } from "@/models/search/product/SearchProduct";

const confirmedDiscard = ref(false);
const customerId = ref("");
const customer = ref<Customer | null>(null);
const products = ref<SearchProduct[] | null>(null);

const route = useRoute();
const router = useRouter();

const { getCustomer } = useCustomer();
const { frequentlyBoughtProducts } = useSearch();

const fetchCustomerData = async () => {
  customer.value = null;
  products.value = null;

  customerId.value = route.params.id as string;
  if (customerId.value.length < 30) {
    routeToCustomerList();
    return;
  }

  try {
    customer.value = await getCustomer(customerId.value);
    const request = new ProductFrequentlyBoughtRequest("", 8, true);
    const response = await frequentlyBoughtProducts(request);
    products.value = response.products;
  } catch {
    customer.value = null;
  }
};

onMounted(fetchCustomerData);

const routeToCustomerList = () => {
  confirmedDiscard.value = true;
  if (
    window.history.state.back === null ||
    window.history.state.back.indexOf("/customer/search") === -1 ||
    customer.value === null
  ) {
    router.push({ name: "customer-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", customer.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};
</script>
