<template>
  <h4 class="text-center">{{ t("customer.search.filter-customer-type") }}</h4>
  <div
    v-for="(value, index) in allTypesComputed"
    :key="index"
    class="flex justify-between relative items-center p-2 rounded-md mb-6"
    :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
  >
    <div class="centered-child">
      <label class="font-light" :for="'customer-type-' + value.type.toLowerCase()">{{
        t(`customer.type.${value.type.toLowerCase()}`)
      }}</label>
    </div>
    <div class="ml-auto">
      <Checkbox
        :id="'customer-type-' + value.type.toLowerCase()"
        v-model="typesComputed"
        :value="value.type"
        :data-testid="'customer-type-' + value.type.toLowerCase()"
      />
    </div>
  </div>

  <FloatLabel variant="on">
    <MultiSelect
      id="customer-groups"
      v-model="selectedCustomerGroupsIdComputed"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('customer.search.filter-customer-group').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="customer-groups"
      option-label="name"
      option-value="id"
      :options="customerGroups"
      display="chip"
      class="w-full field"
      :filter="true"
      :loading="loading"
    />
    <label for="customer-groups" class="text-base">{{ t("customer.search.filter-customer-group") }}</label>
  </FloatLabel>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { SearchFilters } from "@/models/search/customer/SearchFilters";
import { useI18n } from "vue-i18n";
import { CustomerType } from "@multicase/portal-api/customer";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";

const { t } = useI18n();
const props = defineProps<{
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: SearchFilters): void;
}>();

const typesComputed = computed<CustomerType[]>({
  get: () => props.filters.customerTypes,
  set: (value) => {
    const filters = { ...props.filters, customerTypes: value };
    emit("update:filters", filters);
  },
});

const allTypesComputed = computed(() => {
  const allTypes = Object.values(CustomerType);
  return allTypes.map((type, index) => {
    return {
      type: type,
      index: index,
    };
  });
});

const selectedCustomerGroupsIdComputed = computed<string[]>({
  get: () => props.filters.customerGroups,
  set: (value) => {
    const filters = { ...props.filters, customerGroups: value };
    emit("update:filters", filters);
  },
});
</script>
