<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-4"
          @click="router.push({ name: 'customer-add' })"
          data-testid="btn-add"
        >
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("customer.header.add-customer") }}</span>
        </PrimeButton>
      </div>
      <div class="c-header-filter-container c-header-icon-container">
        <FilterOverview v-model:filters="filtersComputed" :customer-groups="customerGroups" />
        <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-4">{{
            showSidebar ? t("customer.search.hide-filter") : t("customer.search.show-filter")
          }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { SearchFilters } from "@/models/search/customer/SearchFilters";
import FilterOverview from "./FilterOverview.vue";
import { computed } from "vue";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";

const router = useRouter();

const { t } = useI18n();

const props = defineProps<{
  showSidebar: boolean;
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
}>();

const emit = defineEmits<{
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: SearchFilters): void;
}>();

const filtersComputed = computed({
  get() {
    return props.filters;
  },
  set(value: SearchFilters) {
    emit("update:filters", value);
  },
});
</script>
