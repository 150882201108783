<template>
  <Card class="col-span-12 md:col-span-6 2xl:col-span-4">
    <template #title>
      <h2 v-if="customer !== null">Customer information</h2>
    </template>

    <template #content>
      <div v-if="customer === null">
        <ProgressSpinner class="w-12 flex items-center" />
      </div>

      <div v-else class="grid grid-cols-12 gap-6 rounded-lg text-gray-500 bg-stone-100">
        <div class="col-span-12 xl:col-span-6 p-6">
          <ul>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.number") }}</div>
                <div class="w-3/5">{{ customer?.customerNumber }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.name") }}</div>
                <div class="w-3/5">{{ customer?.name }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.phone") }}</div>
                <div class="w-3/5">{{ customer?.phoneNumber }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.address") }}</div>
                <div class="w-3/5">
                  <div v-for="line in customer?.address.addressLines" :key="line">
                    {{ line }}
                  </div>
                  <div>{{ customer?.address.postalCode }} {{ customer?.address.city }}</div>
                </div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.country") }}</div>
                <div class="w-3/5">{{ t(`overview.country-code.${customer?.address.countryIso}`) }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.email") }}</div>
                <div class="w-3/5">{{ customer?.email }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.phone") }}</div>
                <div class="w-3/5">{{ customer?.phoneNumber }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-span-12 xl:col-span-6 p-6">
          <ul>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.status") }}</div>
                <div class="w-3/5 text-primary-500">{{ customer?.customerState }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.currency") }}</div>
                <div class="w-3/5">{{ customer?.payment.currencyIso }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.payment-term") }}</div>
                <div class="w-3/5">{{ customer?.payment.defaultPaymentTermId }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.credit-limit") }}</div>
                <div class="w-3/5">{{ customer?.payment.creditLimit }}</div>
              </div>
            </li>
            <li>
              <div class="flex mb-2">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.cutomer-type") }}</div>
                <div class="w-3/5">{{ customer?.customerType }}</div>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.customer-group") }}</div>
                <div class="w-3/5">{{ customer?.customerGroupIds }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="customer !== null && customer.customerType === CustomerType.B2B" class="grid grid-cols-12 gap-6 mt-4">
        <div class="col-span-12">
          <DataTable :value="customer.businessCustomer.contacts">
            <Column field="firstName" header="Name"></Column>
            <Column field="email" header="email"></Column>
            <Column field="phoneNumber" header="Phone"></Column>
          </DataTable>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-if="customer !== null" class="flex justify-end mt-1 -mb-3">
        <PrimeButton label="See all" class="" text pt:label:class="font-medium" />
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { CustomerType } from "@/models/customer/CustomerType";
import { Customer } from "@/models/customer/Customer";

const { t } = useI18n();
const customer = defineModel<Customer | null>("customer", { required: true });
</script>
