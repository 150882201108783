<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        icon="pi pi-arrow-left"
        data-testid="btn-cancel"
        rounded
        size="small"
        variant="text"
        @click="routeToCustomerList"
      />
      <div class="inline-block ml-4 text-lg lg:w-36 xl:w-auto truncate xl:text-wrap align-middle">
        <span class="text-slate-800">{{ customer.customerNumber }} - {{ customer.name }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center">
      <SelectButton
        v-model="selectedTab"
        class="c-tabs"
        :allow-empty="false"
        :options="tabOptions"
        :pt="{ label: 'w-full' }"
      >
        <template #option="slotProps">
          <div class="truncate w-20 xl:w-full">
            {{ t(`customer.tabs.${slotProps.option}`) }}
          </div>
        </template>
      </SelectButton>
    </div>

    <div
      id="teleport-edit-customer-toolbar"
      class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5"
    ></div>
  </div>

  <template v-if="selectedTab === 'overview'">
    <CustomerOverview />
  </template>

  <template v-if="selectedTab === 'details'">
    <CustomerEdit />
  </template>

  <template v-if="selectedTab === 'orders'">
    <teleport to="#teleport-edit-customer-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>

  <template v-if="selectedTab === 'invoices'">
    <teleport to="#teleport-edit-customer-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>

  <template v-if="selectedTab === 'credit-notes'">
    <teleport to="#teleport-edit-customer-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import CustomerOverview from "../components/overview/CustomerOverview.vue";
import CustomerEdit from "../components/details/CustomerEdit.vue";
import { useCustomer } from "@/composables/useCustomers";

const selectedTab = ref("");
const tabOptions = ref(["overview", "details", "orders", "invoices", "credit-notes"]);

const { t } = useI18n();

const { customer, getCustomer, routeToCustomerList } = useCustomer();

const fetchCustomerData = async () => {
  const customerId = route.params.id as string;
  await getCustomer(customerId);
};

const route = useRoute();
const router = useRouter();

const currentUrlTab = ref("");

onMounted(async () => {
  currentUrlTab.value = (route.query.tab as string) ?? "overview";
  selectTab(currentUrlTab.value);

  await fetchCustomerData();
});

const selectTab = (tabName: string) => {
  switch (tabName) {
    case "overview":
    case "details":
    case "orders":
    case "invoices":
    case "credit-notes":
      break;
    default:
      tabName = "overview";
      break;
  }

  selectedTab.value = tabName;
};

watch(
  () => selectedTab.value,
  (tab: string) => {
    if (tab === currentUrlTab.value) return;

    //Load all data after changes
    router.replace({
      query: {
        ...router.currentRoute.value.query,
        tab: tab,
      },
    });
  },
);
</script>
