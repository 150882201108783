import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { BadRequestError, InternalServerError, NotFoundError } from "@cumulus/http";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { useI18n } from "vue-i18n";

export function useErrorHandler() {
  const { instance } = useAuth();
  const toast = useCumulusToast(useToast());
  const { t } = useI18n();

  const handleError = async (error: unknown): Promise<void> => {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
      return;
    }

    let rethrow = false;
    let errorMessage = "";
    if (error instanceof InternalServerError) {
      errorMessage = t("common.error-detail.server-error");
    } else if (error instanceof NotFoundError) {
      errorMessage = t("common.error-detail.not-found");
    } else if (error instanceof BadRequestError) {
      errorMessage = t("common.error-detail.bad-request");
    } else {
      rethrow = true;

      console.error(error);
    }

    if (errorMessage !== "") {
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: errorMessage,
        closable: true,
      });
    }

    if (rethrow) {
      throw error;
    }
  };

  return { handleError };
}
