<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        :id="id ?? 'defaultId'"
        v-model="phoneNumberCoputed"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('customer.phone').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        :data-testid="id ?? 'defaultId'"
        :aria-describedby="id + '-help'"
        :value="props.modelValue ?? ''"
        type="tel"
        maxlength="50"
        :label="labelComputed"
        class="w-full"
        :fluid="true"
        @keydown="validateKey($event)"
        @focus="selectAllOnFocus"
        @input="handleInput"
      />
      <label for="customer-phone">
        {{ t(`customer.phone`) }}
      </label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  id: string;
  label?: string;
  labelClass?: string;
  hideLabel?: boolean;
  modelValue: string;
  placeholder?: string;
  cssClass?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", modelValue: string): void;
}>();

const { t } = useI18n();

const labelComputed = computed(() => props.label ?? t(`common.phone-label`));

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const inputRef = ref<HTMLInputElement>();
const focus = () => {
  if (inputRef.value) {
    inputRef.value.focus();
  }
};

const phoneNumberCoputed = computed<string>({
  get: () => {
    return props.modelValue ?? "";
  },
  set: (value) => {
    emit("update:modelValue", value);
  },
});

defineExpose({
  focus,
});

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const sanitizedValue = sanitizePhoneNumberInput(target.value);
  emit("update:modelValue", sanitizedValue);
};

const sanitizePhoneNumberInput = (input: string): string => {
  // Replace any non-numeric, non '+' or '-' characters with empty string
  return input.replace(/[^0-9+-]/g, "");
};

const validateKey = (event: KeyboardEvent) => {
  // Allow numeric keys, backspace, delete, +, -
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "-",
    "+",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "Home",
    "End",
    "Tab",
  ];

  if (!allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
};
</script>
