<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <Select
        id="customer-state"
        v-model="customerStateComputed"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('customer.customer-status').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        :options="options"
        option-label="name"
        option-value="value"
        data-testid="customer-state"
        class="w-full"
        :fluid="true"
      />

      <label for="customer-state">
        {{ t(`customer.customer-status`) }}
      </label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { CustomerState } from "@multicase/portal-api/customer";

const props = defineProps<{
  customerState: CustomerState;
}>();

const emit = defineEmits<{
  (e: "update:customerState", value: string): void;
}>();

const options = ref<Record<string, string>[]>([]);

const fetchCustomerStates = () => {
  for (const state in CustomerState) {
    options.value.push({
      name: t(`common.state.${state.toString().toLowerCase()}`),
      value: state,
    });
  }
};

onMounted(fetchCustomerStates);

const customerStateComputed = computed<string>({
  get: () => {
    return props.customerState ?? CustomerState.Active;
  },
  set: (value) => {
    emit("update:customerState", value);
  },
});

const { t } = useI18n();
</script>
