<template>
  <teleport to="#teleport-edit-customer-toolbar">
    <Button class="c-circular-button" @click="onToggleEditMode">
      <i class="pi pi-cog c-default-button c-circular-icon"></i>
      <span class="px-3">{{
        isStaticGrid ? t("overview.grid.configure-open") : t("overview.grid.configure-close")
      }}</span>
    </Button>
  </teleport>

  <div class="c-page-content mt-1 ml-5 pr-1" :class="{ 'edit-grid-mode': !isStaticGrid }">
    <section class="grid-stack">
      <div
        v-for="(component, key, index) in components"
        :key="'component' + index"
        :gs-id="key"
        class="grid-stack-item"
        :gs-x="component.gridPos.x"
        :gs-y="component.gridPos.y"
        :gs-h="component.gridPos.h"
        :gs-w="component.gridPos.w"
        gs-auto-position="true"
        :gs-no-resize="component.noResize"
        :gs-no-move="component.noMove"
      >
        <div class="grid-stack-item-content">
          <component :is="component.name" v-bind="component.props" />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, reactive } from "vue";
import { useI18n } from "vue-i18n";
import CustomerInformation from "./CustomerInformation.vue";
import InvoiceSalesChart from "./InvoiceSalesChart.vue";
import FrequentlyBoughtProducts from "./FrequentlyBoughtProducts.vue";
import { useRoute } from "vue-router";
import { useCustomer } from "@/composables/useCustomers";
import { useSearch } from "../../api/search/SearchService";
import { ProductSearchRequest } from "@/models/search/product/ProductSearchRequest";
import type { SearchProduct } from "@/models/search/product/SearchProduct";
import { GridStack, type GridStackOptions } from "gridstack";
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";

const { t } = useI18n();
const products = ref<SearchProduct[] | null>(null);

const route = useRoute();

const { customer, getCustomer, routeToCustomerList } = useCustomer();
const { productSearch } = useSearch();

const fetchCustomerData = async () => {
  products.value = null;

  const customerId = route.params.id as string;
  if (customerId.length < 30) {
    routeToCustomerList();
    return;
  }

  await getCustomer(customerId);
  const request = new ProductSearchRequest("", 8, true);
  const response = await productSearch(request);
  products.value = response.products;
};

onMounted(fetchCustomerData);

let grid: GridStack | null = null;

const components = reactive({
  component1: {
    name: markRaw(CustomerInformation),
    props: {
      customer: customer,
    },
    gridPos: { x: 0, y: 0, w: 4, h: 5 },
    noResize: false,
    noMove: false,
  },
  component2: {
    name: markRaw(InvoiceSalesChart),
    props: {
      customer: customer,
    },
    gridPos: { x: 4, y: 0, w: 4, h: 5 },
    noResize: false,
    noMove: false,
  },
  component3: {
    name: markRaw(FrequentlyBoughtProducts),
    props: {
      products: products,
    },
    gridPos: { x: 8, y: 0, w: 4, h: 7 },
    noResize: false,
    noMove: false,
  },
});

const isStaticGrid = ref(true);
const onToggleEditMode = () => {
  if (grid) {
    isStaticGrid.value = !isStaticGrid.value;
    grid.setStatic(isStaticGrid.value);

    if (!isStaticGrid.value) {
      grid.save(true);
    }
  }
};

onMounted(() => {
  const opts: GridStackOptions = {
    margin: 5,
    cellHeight: "38rem", // = 48px
    sizeToContent: true, // default to make them all fit
    resizable: { handles: "all" }, // do all sides for testing
    acceptWidgets: true,
    float: false,
    staticGrid: true,

    columnOpts: {
      breakpointForWindow: true, // test window vs grid size
      breakpoints: [
        { w: 700, c: 1 },
        { w: 850, c: 2 },
        { w: 950, c: 8 },
        { w: 1100, c: 12 },
      ],
      layout: "moveScale",
    },
  };

  grid = GridStack.init(opts);

  grid.enableResize(false);

  grid.setStatic(isStaticGrid.value);
});
</script>

<style scoped lang="scss">
.edit-grid-mode {
  .grid-stack {
    background: #cce7f8;
  }

  .grid-stack.grid-stack-static {
    background: #eee;
  }

  .ui-draggable-disabled.ui-resizable-disabled > .grid-stack-item-content {
    background-color: #777;
  }

  .grid-stack-item-removing {
    opacity: 0.5;
  }

  .grid-stack .grid-stack-item-content:hover {
    cursor: move;
  }
}
</style>
