<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <Header
    :editMode="true"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @onDelete="onDeleteCustomer"
    @onCommit="onSave"
    @onCancel="onCancel"
    @stayOnPage="stayOnPage"
    @routeToCustomerList="routeToCustomerList"
    :collapsedPanels="panelCollapsed"
    @onToggleAllPanels="onToggleAllPanels()"
  />

  <div v-if="!loadFailed && !isLoading" class="c-edit-customer mt-4 mb-28">
    <div class="c-content mb-6">
      <div class="c-content-top">
        <div class="c-content-top-left">
          <Card class="c-card-font pb-5 mr-4">
            <template #content>
              <div class="w-full mr-5">
                <div class="c-created flex justify-end">
                  <span class="c-created-text">{{ t("common.created") }}: </span>
                  <span class="c-created-date" data-testid="registered-date">{{ registeredDateComputed }}</span>
                </div>
              </div>

              <div class="grid grid-cols-2 gap-4">
                <div>
                  <FirstName
                    v-if="!isBusinessCustomer"
                    v-model:firstName="customer.privateCustomer.firstName"
                    :focusInput="true"
                  />
                  <CompanyName
                    v-if="isBusinessCustomer"
                    v-model:companyName="customer.businessCustomer.companyName"
                    :focusInput="true"
                  />
                </div>
                <div>
                  <OrganizationNumber
                    v-if="isBusinessCustomer"
                    v-model:organizationNumber="customer.businessCustomer.organizationNumber"
                  />
                  <LastName v-if="!isBusinessCustomer" v-model:lastName="customer.privateCustomer.lastName" />
                </div>
                <div>
                  <CustomerEmail v-model:emailAddress="customer.email" />
                </div>
                <div>
                  <PhoneInput id="customer-phone" v-model="customer.phoneNumber" />
                </div>

                <div class="mb-0">
                  <CustomerNumber v-model:customerNumber="customer.customerNumber" />
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right">
          <Card class="c-card-font mb-4">
            <template #content>
              <div class="flex flex-wrap mt-6">
                <div class="c-col-1 px-2 -mx-3 mb-4">
                  <div class="mt-3 mb-3">
                    <CustomerType v-model:customerType="customer.customerType" />
                  </div>

                  <div class="mb-4 pt-2">
                    <CustomerState v-model:customerState="customer.customerState" />
                  </div>

                  <div class="pt-2">
                    <DocumentLanguage v-model:documentLanguage="customer.documentLanguageIso" />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>

    <Panel
      :header="t('common.addresses')"
      toggleable
      :collapsed="panelCollapsedAdresses"
      class="c-card-font mb-4"
      :pt:header:onClick="() => (panelCollapsedAdresses = !panelCollapsedAdresses)"
    >
      <div class="xl:flex 2xl:flex lg:flex md:flex sm:flex mb-4">
        <div class="w2-full flex-1 xl:border-r-2 2xl:border-r-2">
          <Address
            :selectedCountry="customer.deliveryAddress.countryIso"
            :address="customer.deliveryAddress"
            :countryOptions="countryOptions"
            @setAddress="setDeliveryAddress"
            @autoSelectCountry="autoSelectedCountry"
            :addressType="addressTypeDelivery"
            :addressLabel="t('customer.address.delivery')"
          />
        </div>
        <div class="w-full flex-1 pl-3">
          <Address
            :selectedCountry="customer.address.countryIso"
            :address="customer.address"
            :countryOptions="countryOptions"
            @setAddress="setCompanyAddress"
            @autoSelectCountry="autoSelectedCountry"
            :addressType="addressTypeInvoice"
            :addressLabel="t('customer.address.invoice')"
          />
        </div>
      </div>
    </Panel>

    <Panel :header="t('customer.terms-and-agreements')" toggleable :collapsed="panelCollapsed" class="c-card-font mb-4">
      <div class="grid grid-cols-12 mb-4">
        <Card class="col-span-12 lg:col-span-7 shadow-none" pt:body:class="px-2 py-0">
          <template #content>
            <div class="col-span-6 lg:col-span-3">
              <div class="grid grid-cols-4">
                <div class="mb-2 mt-4">
                  <DefaultFreightMethod
                    v-model:defaultFreightMethod="customer.freight.defaultFreightMethodId"
                    :freightMethods="freightMethods"
                  />
                </div>
                <div class="mb-2 mt-4">
                  <DefaultPaymentTerm v-model:paymentTermId="customer.payment.defaultPaymentTermId" />
                </div>
                <div class="mb-2 mt-4">
                  <CreditLimit v-model:creditLimit="customer.payment.creditLimit" />
                </div>
                <div class="mb-2 mt-4 mr-8">
                  <Currency v-model:currency="customer.payment.currencyIso" />
                </div>
              </div>
            </div>
            <div class="col-span-6 lg:col-span-3">
              <div class="mb-2 mt-4">
                <FreightAgreements
                  v-model:freightAgreements="customer.freight.freightAgreements"
                  :freightMethods="freightMethods"
                />
              </div>
            </div>
          </template>
        </Card>

        <Card class="col-span-12 lg:col-span-5 shadow-none lg:border-l rounded-none" pt:body:class="ml-2 p-0">
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-5 lg:border-r">
                <CustomerGroup v-model:customerGroups="customer.customerGroupIds" />
              </div>
              <div class="col-span-7">
                <Documents v-model:documents="customer.documents" />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </Panel>
    <Panel
      :header="t('customer.contact-list.label')"
      toggleable
      :collapsed="panelCollapsedContactList"
      class="c-card-font mb-4"
      v-if="isBusinessCustomer"
      :pt:header:onClick="() => (panelCollapsedContactList = !panelCollapsedContactList)"
    >
      <div class="w-full">
        <ContactList v-model:contacts="customer.businessCustomer.contacts" />
      </div>
    </Panel>

    <div v-if="loadFailed">
      <Card class="c-card-font">
        <template #content>
          <div>{{ t("common.loading-failed") }} {{ errorReason }}</div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import CustomerNumber from "@/components/CustomerNumber.vue";
import OrganizationNumber from "@/components/OrganizationNumber.vue";
import CustomerType from "@/components/CustomerType.vue";
import Address from "@/components/Address.vue";
import CreditLimit from "@/components/CreditLimit.vue";
import Currency from "@/components/Currency.vue";
import CustomerState from "@/components/CustomerState.vue";
import DefaultPaymentTerm from "@/components/DefaultPaymentTerm.vue";
import CustomerGroup from "@/components/customer-group/CustomerGroup.vue";
import DefaultFreightMethod from "@/components/DefaultFreightMethod.vue";
import FreightAgreements from "@/components/FreightAgreements.vue";
import FirstName from "@/components/FirstName.vue";
import LastName from "@/components/LastName.vue";
import CompanyName from "@/components/CompanyName.vue";
import CustomerEmail from "@/components/CustomerEmail.vue";
import PhoneInput from "@/components/PhoneInput.vue";
import ContactList from "@/components/ContactList.vue";
import Header from "@/components/Header.vue";
import DocumentLanguage from "@/components/DocumentLanguage.vue";
import Documents from "@/components/Documents.vue";
import { customerAddressFunctions } from "@/hooks/CustomerAddressFunction";

import { CustomerType as CustomerTypeModel } from "@/models/customer/CustomerType";
import { Country } from "@/models/country/Country";
import { Customer } from "@/models/customer/Customer";
import { useCustomer } from "@/api/customer/CustomerService";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useCountry } from "@/api/country/CountryService";
import { useFreightMethod } from "@/repositories/freight-method/FreightMethodService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const { getCustomer, updateCustomer, deleteCustomer } = useCustomer();
const { getAllCountries } = useCountry();
const { getAllFreightMethods } = useFreightMethod();

const { t, d } = useI18n();
const toast = useCumulusToast(useToast());
const route = useRoute();
const router = useRouter();
const customerId = ref("");
const countryOptions = ref<Country[]>([]);
const confirmedDiscard = ref(false);

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const routeToCustomerList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/customer/search") === -1) {
    router.push({ name: "customer-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", customer.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const loadFailed = ref(false);
const isLoading = ref(true);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const errorReason = ref("");
const customer = ref(new Customer());
const initialCustomer = ref<Customer | null>(null);
const addressTypeDelivery = ref("delivery");
const addressTypeInvoice = ref("invoice");
const { setCompanyAddress, setDeliveryAddress } = customerAddressFunctions(customer);

//Nested validation
const val = useValidate();

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const fetchCustomerData = async () => {
  customerId.value = route.params.id as string;
  isLoading.value = true;
  loadFailed.value = false;

  try {
    customer.value = await getCustomer(customerId.value);
    initialCustomer.value = cloneDeep(customer.value);
  } catch {
    loadFailed.value = true;
  } finally {
    isLoading.value = false;
  }
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onSave = async () => {
  unsavedChangesDialogVisible.value = false;
  //Wait for onblur functions
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await updateCustomer(customer.value);

  toast.add({
    severity: "success",
    summary: t("customer.toast.edit.success-summary"),
    detail: t("customer.toast.edit.success-detail"),
    closable: true,
  });

  routeToCustomerList();
};

const onDeleteCustomer = async () => {
  await deleteCustomer(customer.value.id);

  toast.add({
    severity: "success",
    summary: t("customer.toast.delete.success-summary"),
    closable: true,
  });

  routeToCustomerList();
};

const isBusinessCustomer = computed(() => {
  return customer.value.customerType === CustomerTypeModel.B2B;
});

const autoSelectedCountry = (countryIso: string) => {
  if (customer.value.deliveryAddress.countryIso === "") {
    customer.value.deliveryAddress.countryIso = countryIso;
    customer.value.address.countryIso = countryIso;
  }
};

const freightMethods = ref<FreightMethod[]>([]);
const fetchFreightMethods = async () => {
  freightMethods.value = await getAllFreightMethods();
};
const registeredDateComputed = computed(() => {
  return customer.value.registeredDate ? d(customer.value.registeredDate, "short") : "";
});

const hasUpdates = computed(() => {
  return !isEqual(customer.value, initialCustomer.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToCustomerList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

const panelCollapsedAdresses = ref(false);
const panelCollapsedTermsAgreement = ref(false);
const panelCollapsedContactList = ref(false);

const panelCollapsed = computed(() => {
  return panelCollapsedAdresses.value && panelCollapsedTermsAgreement.value && panelCollapsedContactList.value;
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedAdresses.value = newState;
  panelCollapsedTermsAgreement.value = newState;
  panelCollapsedContactList.value = newState;
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([fetchCustomerData(), fetchCountryData(), fetchFreightMethods()]);
});
const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToCustomerList();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

useShortcut(ShortcutAction.save, onSave);
window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});
</script>

<style scoped lang="scss">
.c-border-div {
  padding: 0.5rem;
  border-right: var(--footer-border);

  @media screen and (max-width: 992px) {
    border-right: none;
  }
}

.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 75%;
}
.c-content-top-right {
  flex: 25%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-purchase-order-lines-card {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.c-card-font {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
