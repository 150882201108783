<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        id="company-name"
        data-testid="company-name"
        v-model="companyNameComputed"
        class="w-full"
        :invalid="val.$error"
        :fluid="true"
        :maxlength="500"
        aria-describedby="company-name-help"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('customer.company-name').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label for="company-name" class="c-required">
        {{ t(`customer.company-name`) }}
      </label>
    </FloatLabel>
  </div>
  <small class="p-error" v-if="val.$error" data-testid="company-name-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  companyName: string;
}>();

const emit = defineEmits<{
  (e: "update:companyName", value: string): void;
  (event: "blur", value: string): void;
}>();

const companyNameComputed = computed<string>({
  get: () => {
    return props.companyName ?? "";
  },
  set: (value) => {
    emit("update:companyName", value);
  },
});

const rules = {
  companyName: { required },
};

const val = useVuelidate(rules, props);
</script>
