<template>
  <Card class="col-span-12 md:col-span-6 2xl:col-span-4">
    <template #title><div v-if="products !== null">Frequently bought products (last x months)</div></template>
    <template #subtitle><div v-if="products !== null">Subtitle...</div></template>
    <template #content>
      <div v-if="products === null">
        <ProgressSpinner class="w-12 flex items-center" />
      </div>
      <div v-else>
        <DataTable
          v-model:selection="selectedProduct"
          :value="products"
          selectionMode="single"
          dataKey="id"
          @row-click="onRowClicked"
        >
          <Column field="productNumber" :header="t('overview.product.number')"></Column>
          <Column field="name" :header="t('overview.product.name')"></Column>
          <Column :header="t('overview.product.stock')" class="text-right">
            <template #body="{ data }">
              {{ getProductStock(data) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
    <template #footer>
      <div v-if="products !== null" class="flex justify-end mt-1 -mb-3">
        <PrimeButton label="See all" class="" text pt:label:class="font-medium" />
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { ProductAvailability } from "@/models/search/product/ProductAvailability";
import { SearchProduct } from "../../models/search/product/SearchProduct";
import { ref } from "vue";
import { DataTableRowClickEvent } from "primevue/datatable";

const { t } = useI18n();
const router = useRouter();

const products = defineModel<SearchProduct[] | null>("products", { required: true });

const getProductStock = (product: SearchProduct) => {
  return product.availability.reduce((accumulator: number, currentValue: ProductAvailability) => {
    return currentValue.quantityAvailableFromStock + accumulator;
  }, 0);
};

const selectedProduct = ref<SearchProduct | null>(null);
const onRowClicked = (event: DataTableRowClickEvent) => {
  router.push({ path: "/product/edit/" + event.data.id });
};
</script>
