import { Address, BusinessCustomer, CustomerType } from "@multicase/portal-api/customer";

export class SearchCustomer {
  id = "";
  companyId = "";
  clientId = "";
  customerNumber = "";
  name = "";
  email = "";
  phoneNumber = "";
  address = new Address();
  deliveryAddress = new Address();
  customerGroupIds: string[] = [];
  customerType: CustomerType = CustomerType.Business;
  businessCustomer: BusinessCustomer = new BusinessCustomer();
  registeredDate = "";
}
