<template>
  <Tabs v-model:value="selectedTab" class="c-toolbar-tab">
    <TabList>
      <Tab value="0">Overview</Tab>
      <Tab value="1">Details</Tab>
      <Tab value="2">Orders</Tab>
      <Tab value="3">Invoce</Tab>
      <Tab value="4" class="border-0">Credit notes</Tab>
    </TabList>
    <TabPanels>
      <TabPanel value="0">
        <keep-alive>
          <component :is="overviewComponent"></component>
        </keep-alive>
      </TabPanel>
      <TabPanel value="1">
        <component :is="detailsComponent"></component>
      </TabPanel>
      <TabPanel value="2">
        <p class="m-0">Orders...</p>
      </TabPanel>
      <TabPanel value="3">
        <p class="m-0">Invoce...</p>
      </TabPanel>
      <TabPanel value="4">
        <p class="m-0">Credit notes...</p>
      </TabPanel>
    </TabPanels>
  </Tabs>
</template>

<script setup lang="ts">
import { ref, onMounted, shallowRef, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import CustomerOverview from "../components/overview/CustomerOverview.vue";
import CustomerEdit from "../components/details/CustomerEdit.vue";

const route = useRoute();
const router = useRouter();

const selectedTab = ref("0");
const overviewComponent = shallowRef();
const detailsComponent = shallowRef();

onMounted(() => {
  const tabIndex = route.query.index as string;
  if (tabIndex === undefined || isNaN(+tabIndex) || +tabIndex === 0 || +tabIndex < 0 || +tabIndex > 4) {
    selectedTab.value = "0";
    overviewComponent.value = CustomerOverview;
    return;
  }

  selectedTab.value = tabIndex;
});

const selectTab = (index: string) => {
  switch (index) {
    case "0":
      overviewComponent.value = CustomerOverview;
      break;
    case "1":
      console.log("Tab 1");
      detailsComponent.value = CustomerEdit;
      break;
    case "2":
      console.log("Tab 2");
      break;
    case "3":
      console.log("Tab 3");
      break;
    case "4":
      console.log("Tab 4");
      break;
    default:
      selectedTab.value = "0";
      break;
  }
};

watch(
  () => selectedTab.value,
  (tabIndex) => {
    //Update queryparams with out reload, vue-router reloads the page
    // const newurl =
    //   window.location.protocol + "//" + window.location.host + window.location.pathname + "?index=" + tabIndex;
    // window.history.pushState({ path: newurl }, "", newurl);

    //Load all data after changes
    router.replace({
      query: {
        ...router.currentRoute.value.query,
        index: tabIndex,
      },
    });

    selectTab(tabIndex);
  }
);
</script>

<style scoped lang="scss"></style>
