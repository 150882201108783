<template>
  <Popover
    id="overlay_panel"
    ref="customerGroupPanelRef"
    :dismissable="true"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '400px' }"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <CustomerGroupForm
      v-if="openCustomerGroupForm"
      :customer-group="customerGroup"
      @close-form="openCustomerGroupForm = false"
    />

    <div v-else>
      <CustomerGroupList
        v-model:selected-customer-groups="selectedCustomerGroupsComputed"
        @create-new-customer-group="createNewCustomerGroupFromSearch"
        @edit-customer-group="editCustomerGroup"
        @hide-overlay-panel="hideOverlayPanel"
      />

      <div class="formgrid grid grid-cols-12 gap-4 mt-4">
        <div class="col-span-9 lg:col-span-9 flex flex-col">
          <div class="flex justify-end">
            <Button
              type="button"
              :label="t('common.close')"
              class="p-button-secondary mr-4"
              data-testid="customer-group-close-panel"
              severity="cancel"
              text
              :pt="{
                label: 'font-bold',
              }"
              icon="pi pi-times"
              @click="hideOverlayPanel"
            ></Button>
          </div>
        </div>
        <div class="col-span-3 lg:col-span-3 flex flex-col">
          <div class="flex justify-end">
            <Button
              type="button"
              :label="t('common.create')"
              class="p-button-info"
              data-testid="customer-group-create-new"
              icon="pi pi-check"
              @click="createNewCustomerGroup"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </Popover>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import CustomerGroupList from "./CustomerGroupList.vue";
import CustomerGroupForm from "./CustomerGroupForm.vue";

const props = defineProps<{
  selectedCustomerGroups: string[];
}>();

const emit = defineEmits<{
  (e: "update:selectedCustomerGroups", value: string[]): void;
}>();

const { t } = useI18n();
const openCustomerGroupForm = ref(false);
const customerGroup = ref<CustomerGroup>(new CustomerGroup());

const selectedCustomerGroupsComputed = computed<string[]>({
  get: () => props.selectedCustomerGroups ?? [],
  set: (value) => {
    emit("update:selectedCustomerGroups", value);
  },
});

const createNewCustomerGroupFromSearch = (cg: CustomerGroup) => {
  customerGroup.value = cg;
  openCustomerGroupForm.value = true;
};

const createNewCustomerGroup = () => {
  customerGroup.value = new CustomerGroup();
  openCustomerGroupForm.value = true;
};

const editCustomerGroup = (cg: CustomerGroup) => {
  customerGroup.value = cg;
  openCustomerGroupForm.value = true;
};

const customerGroupPanelRef = ref();
const hideOverlayPanel = () => {
  customerGroupPanelRef.value.hide();
};

const toggle = (event: Event) => {
  customerGroupPanelRef.value.toggle(event);
};

defineExpose({ toggle });
</script>
