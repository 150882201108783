import { httpClient, AuthHeaders } from "@cumulus/http";

import { CustomerSearchRequest } from "../../models/search/customer/CustomerSearchRequest";
import { CustomerSearchResponse } from "../../models/search/customer/CustomerSearchResponse";

import { ProductFrequentlyBoughtRequest } from "@/models/search/product/ProductFrequentlyBoughtRequest";
import { ProductFrequentlyBoughtResponse } from "@/models/search/product/ProductFrequentlyBoughtResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != "undefined" && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async customerSearch(
    authHeaders: AuthHeaders,
    request: CustomerSearchRequest
  ): Promise<CustomerSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/customers/search", request)
      .then(({ data }) => data);
  }

  public async frequentlyBoughtProductsSearch(
    authHeaders: AuthHeaders,
    request: ProductFrequentlyBoughtRequest
  ): Promise<ProductFrequentlyBoughtResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/products/search", request)
      .then(({ data }) => data);
  }
}

export const searchApi = new SearchApi();
